* {
    padding: 0px;
    margin: 0px;
    scroll-behavior: smooth;
}


/* 404 */

.error-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 100px;
    background-color: var(--blanco);
    width: 50%;
    padding: 30px;
    margin: auto;
    border-radius: 10px;
    gap: 20px;
    padding-top: 40px;
    text-align: center;
    padding-bottom: 40px;
}

.error-404 a {
    color: var(--blanco);
    background-color: var(--azul);
    font-family: var(--rajdhani);
    text-decoration: unset;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
}


/* Generales */

:root {
    --celeste: #dde5ea;
    --celeste-oscuro: #4d748a;
    --negro: #1e272e;
    --negro-claro: #27323a;
    --azul: #268dc2;
    --azul-claro: #43a7da;
    --blanco: #fff;
    --gris: #6b7280;
    --gris-claro: #d1d5db;
    --verde: #2ecc71;
    --rojo: #e74c3c;
    --rajdhani: "rajdhani", sans-serif;
    --site-width: 1350px;
}

.content-hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.content-visible {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--rajdhani);
    color: var(--negro);
    font-weight: 900;
}

h1 {
    font-size: 40px;
    line-height: 40px;
    color: var(--negro);
}

h2 {
    font-size: 30px;
    line-height: 30px;
    color: var(--negro);
}

h3 {
    font-size: 26px;
    line-height: 26px;
    color: var(--negro);
}

h4 {
    font-size: 24px;
    line-height: 24px;
    color: var(--negro);
}

h5 {
    font-size: 22px;
    line-height: 22px;
    color: var(--negro);
}

h6 {
    font-size: 20px;
    line-height: 20px;
    color: var(--negro);
}

p {
    font-family: var(--rajdhani);
    color: var(--gris);
    font-weight: 500;
    font-size: 18px;
}

label {
    font-family: var(--rajdhani);
    color: var(--azul);
    font-weight: bold;
}

input,
textarea,
select {
    border: 0px;
    font-family: var(--rajdhani);
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    outline: none;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    resize: none;
    border-radius: 5px;
    font-size: 14px;
}

select option {
    font-family: var(--rajdhani);
    font-weight: 600;
}

.ql-container {
    min-height: 140px;
    max-height: 140px;
}

.ql-editor p {
    font-size: 14px;
}


/* General */

.site-width {
    max-width: var(--site-width);
    margin: auto;
}

main {
    min-height: 90vh;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
}

.inicio {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}


/* Header */

header {
    background-color: var(--blanco);
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99999;
    transition: all 0.3s;
}

.scrolled {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.grid-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    position: relative;
}

.icono-navideno {
    width: 20px;
    position: absolute;
    top: -7px;
    left: -3px;
}

.logo-link .p-soporte {
    font-family: var(--rajdhani);
    color: var(--blanco);
    font-weight: 700;
    background-color: var(--azul);
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 7px;
    font-size: 12px;
}

.header-logo {
    width: 170px;
}

.user-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.user-actions>a {
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
    font-family: var(--rajdhani);
    text-decoration: none;
    font-weight: 600;
}

.user-actions .login,
.user-actions .dashboard {
    background-color: #abdaf1;
    color: var(--azul);
}

.user-actions .login,
.user-actions .crear-ticket {
    background-color: #ededed;
    color: var(--gris);
}

.user-actions .new-user {
    background-color: var(--azul);
    border: 2px solid var(--azul);
    color: var(--blanco);
}

.modal-notificaciones {
    position: relative;
}

.num-notificacion {
    background-color: var(--azul);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--blanco);
    font-family: var(--rajdhani);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    top: -5px;
    right: -5px;
    font-weight: 600;
}

.dropdown-notificaciones {
    position: absolute;
    bottom: -280px;
    right: 0;
    width: 350px;
    height: 250px;
    background-color: var(--blanco);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    overflow-y: scroll;
    /* Fuerza a que el scroll vertical sea siempre visible */
    scrollbar-width: thin;
    /* Opcional: para navegadores Firefox */
    scrollbar-color: #ccc transparent;
}

.no-pendiente {
    font-size: 14px;
}

.notificaciones {
    display: flex;
    background-color: #f7f7f7;
    padding: 5px;
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
    transition: all 0.3s !important;
}

.notificaciones:hover {
    background-color: #dadada;
}

.notificaciones img {
    width: 20px;
}

.grid-notificaciones {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.grid-notificaciones h3 {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.grid-notificaciones h3 img {
    width: 15px !important;
}

.grid-notificaciones h3>button {
    cursor: pointer;
    border: 0px;
    background-color: #ededed;
    display: flex;
    border-radius: 5px;
    padding: 5px;
}

.notificacion {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    text-decoration: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    transition: all 0.3s;
}

.notificacion:hover {
    background-color: #f7f7f7;
}

.notificacion p {
    font-size: 14px;
}

.notificacion .not-id {
    color: var(--azul);
}


/* Body */

body {
    background-color: var(--celeste);
}

.intro {
    display: flex;
    flex-direction: column;
}

.intro-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.intro-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}

.support-time {
    background-color: var(--negro);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}

.support-text p {
    color: var(--blanco);
}

.support-text p:first-child,
.office-text p:first-child {
    font-weight: 700;
}

.intro-info p {
    font-size: 15px;
}

.intro-info img {
    width: 22px;
}

.office-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    background-color: var(--blanco);
}

.office-text {
    display: flex;
    flex-direction: column;
}

.disponible {
    color: var(--verde) !important;
}

.no-disponible {
    color: var(--rojo) !important;
}

.departments {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px;
    margin-top: 65px;
}

.department {
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-top: 35px;
    padding-bottom: 35px;
    border-radius: 10px;
    position: relative;
    background-color: var(--blanco);
}

.department h2 {
    font-size: 22px;
    margin-bottom: 5px;
}

.department p {
    font-size: 16px;
}

.texto-no-logueado {
    color: var(--azul);
    font-weight: 600;
    margin-top: 10px;
}

.department img {
    width: 20px;
    background-color: var(--azul);
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: -20px;
}

.bg-comunidad {
    background-color: var(--blanco);
    margin-top: 50px;
    padding-top: 85px;
    padding-bottom: 85px;
}

.comunidad {
    display: grid;
    grid-template-columns: 0.35fr 0.65fr;
    gap: 65px;
    place-items: center;
}

.texto-comunidad {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.texto-comunidad h2 span {
    color: var(--azul);
}

.token-ticket {
    font-family: var(--rajdhani);
    text-decoration: unset;
    color: var(--blanco);
    background-color: var(--azul);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    margin-top: 15px;
    border: 0px;
    cursor: pointer;
    width: fit-content;
    font-size: 15px;
    font-weight: 600;
}


/* Inicia sesión */

.inicia-sesion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.formulario-inicio {
    display: flex;
    flex-direction: column;
    width: 430px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
    top: -100px;
    border-radius: 5px;
    padding: 30px;
    padding-bottom: 60px;
    text-align: left;
    background-color: var(--blanco);
}

.formulario-inicio h1 {
    font-size: 30px;
}

.formulario-inicio p {
    font-size: 16px;
}

.campos-inicio {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 30px;
}

.campo-inicio {
    display: flex;
    flex-direction: column;
    position: relative;
}

.campo-inicio label {
    position: absolute;
    top: -15px;
    left: 10px;
    color: var(--azul);
    font-family: var(--rajdhani);
    font-weight: 600;
    font-size: 16px;
    background-color: var(--blanco);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.formulario-inicio input {
    padding: 8px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
    font-size: 14px;
    font-family: var(--rajdhani);
    border-radius: 5px;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    outline: none;
}

.lost-password {
    color: #a3a3a3;
    font-size: 15px !important;
}

.lost-password a {
    color: var(--azul);
}

.formulario-inicio button {
    background-color: var(--azul);
    border: 0px;
    padding: 10px;
    color: var(--blanco);
    font-family: var(--rajdhani);
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
}


/* Dashboard */

.mensaje-panel-warning {
    background-color: #fff0b6;
    border: 2px solid #ffe57e;
    border-radius: 10px;
    position: relative;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.mensaje-panel-warning button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #45495287;
    border: 0px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    color: var(--blanco);
    cursor: pointer;
    font-weight: 800;
    font-family: var(--rajdhani);
}

.mensaje-panel-warning p {
    color: var(--negro);
    font-weight: 600;
    opacity: 0.7;
    font-size: 15px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: 0.25fr 0.75fr;
    gap: 35px;
}

.profile {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    background-color: var(--blanco);
    padding: 40px;
}

.grid-profile-pic {
    position: relative;
    width: fit-content;
    margin: auto;
}

.update-profile-pic input {
    display: none;
}

.update-profile-pic {
    position: absolute;
    bottom: -6px;
    right: 10%;
    width: fit-content;
}

.update-profile-pic img {
    width: 20px;
    cursor: pointer;
    border: 2px solid var(--blanco);
    border-radius: 100%;
}

.profile-pic {
    width: 100px;
    height: 100px;
    margin: auto;
    object-fit: cover;
    border-radius: 500px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.profile-name {
    color: var(--negro);
    font-weight: 800;
    font-size: 16px;
    text-align: center;
}

.profile-name>span {
    color: var(--gris);
    font-weight: 500;
}

.profile-user {
    color: var(--negro);
    font-weight: 800;
    font-size: 16px;
}

.profile-actions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    align-items: center;
    gap: 10px;
    width: 100%;
}

.profile-actions button {
    min-height: 28px;
}

.logout {
    border: 0px;
    background-color: #e74c3c;
    color: var(--blanco);
    padding: 5px;
    font-weight: 600;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    max-height: 20px;
}

.logout p {
    color: var(--blanco);
    font-size: 14px;
}

.logout img {
    width: 12px;
}

.edit-profile {
    border: 0px;
    background-color: #f0f0f0;
    color: var(--negro);
    padding: 5px;
    font-weight: 600;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--rajdhani);
}

.error {
    font-weight: 600;
    color: #e74c3c;
    padding: 10px;
    border-radius: 5px;
    background-color: #ec7a6d69;
    position: relative;
    top: 10px;
    font-size: 14px !important
}

.no-tickets {
    font-weight: 600;
    color: #be9b0f;
    padding: 10px;
    border-radius: 5px;
    background-color: #f1c40f25;
    font-size: 14px !important
}

.profile-and-menu {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.new-bloque {
    background-color: #ffe57e;
}

.bloque-secciones {
    background-color: var(--blanco);
    border-radius: 5px;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bloque-secciones h3 {
    font-size: 20px;
    text-align: center;
}

.bloque-secciones a {
    text-decoration: unset;
}

.bloque-secciones div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: pointer;
}

.bloque-secciones div:hover {
    background-color: #fbfbfb;
}

.bloque-secciones div img {
    width: 20px;
}

.bloque-secciones div p {
    color: var(--negro);
    font-size: 16px;
    user-select: none;
}

.dashboard-stats {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bloque-stats {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: var(--blanco);
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

.bloque-stats p {
    color: var(--negro);
    font-size: 16px;
}

.bloque-stats p>span {
    font-weight: 600;
    color: var(--negro);
}

.bloque-stats img {
    width: 20px;
}


/* Ajustes */

.edit-profile-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.grid-campos-ajustes {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.grid-campos-ajustes button {
    background-color: var(--azul);
    color: var(--blanco);
    border: 0px;
    width: fit-content;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.campo-ajuste {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.campo-ajuste p {
    font-size: 14px;
    position: relative;
    top: -4px;
}

.campo-ajuste p>a {
    color: var(--azul);
}

.texto-edit-profile h3 {
    color: var(--gris);
    font-size: 20px;
}

.texto-edit-profile p {
    font-size: 15px;
}

.grid-campos-ajustes hr {
    border: 0px !important;
    width: 100%;
    height: 2px;
    background-color: var(--gris-claro);
    margin-top: 20px;
    margin-bottom: 20px;
}

.adhoc-warning {
    background-color: #FDF7DF;
    border: 1px solid #FEEC6F;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
}

.warning-ticket {
    margin-top: 0px;
}

.adhoc-warning p {
    color: #C9971C;
    font-size: 14px;
}


/**/

.dashboard-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.tabs button {
    background-color: transparent;
    color: var(--azul-claro);
    font-weight: 600;
    font-family: var(--rajdhani);
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid var(--azul-claro);
    font-size: 15px;
}

.tabs .active {
    background-color: var(--azul);
    color: var(--blanco);
    border: 2px solid var(--azul);
}

.tabcontent div {
    font-family: var(--rajdhani);
}

.grid-historial {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ticket-card {
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    text-decoration: none;
    position: relative;
    background-color: var(--blanco);
}

.identificador-ticket {
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: #2c3e50;
    padding: 5px;
    color: var(--blanco);
    border-bottom-right-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
}

.ticket-preview {
    margin-top: 30px;
}

.ticket-preview h3 {
    font-size: 15px;
    font-family: var(--rajdhani);
}

.dpto-preview {
    background-color: var(--negro);
    color: var(--blanco);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    border-radius: 5px;
}

.ticket-preview-info {
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.encargado {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.encargado img {
    width: 17px;
}

.encargado p {
    font-size: 14px;
}

.dashboard-content {
    padding: 20px;
    background-color: var(--blanco);
    border-radius: 8px;
    padding-top: 30px;
    padding-bottom: 30px;
}


/* Estilo general para la tabla */

.tickets-table th {
    text-align: left;
    padding: 10px;
    border-bottom: 2px solid #dddddd;
}

.tickets-table {
    border-collapse: collapse;
}

.tickets-table thead tr {
    border-bottom: 2px solid #dddddd;
    color: var(--negro);
}

.tickets-table tbody tr {
    border-bottom: 2px solid #dddddd;
    padding-top: 50px !important;
}

.tickets-table td {
    padding: 10px;
    font-size: 15px;
}

.tickets-table td a {
    color: var(--azul);
    font-weight: 600;
}

.assignee img {
    width: 14px;
}

.asignado {
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.asignado p {
    font-size: 15px;
}

.desarrollo {
    font-size: 13px;
    font-weight: 600;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    color: var(--azul) !important;
    background-color: #b3e6ff;
}

.diseno {
    font-size: 13px;
    font-weight: 600;
    color: #e67e22;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #ffe1c6;
}

.marketing {
    font-size: 13px;
    font-weight: 600;
    color: #8e44ad;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #eabaff;
}

.redes-sociales {
    font-size: 13px;
    font-weight: 600;
    color: #1abc9c;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #b1faeb;
}

.no-especificado,
.sin-prioridad {
    font-size: 13px;
    width: fit-content;
    padding: 3px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #edeeee;
    color: #adb0b6 !important;
}

.alta {
    font-size: 13px;
    color: var(--blanco);
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-weight: 600;
    background-color: var(--rojo);
}

.abierto {
    font-size: 13px;
    color: var(--verde) !important;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #e0ffed;
    font-weight: 600;
}

.cerrado {
    font-size: 13px;
    color: var(--blanco) !important;
    width: fit-content;
    padding: 3px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: var(--negro);
}

.retenido {
    font-size: 13px;
    color: var(--negro) !important;
    width: fit-content;
    padding: 3px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #ffbe76;
}

.boton-archivados {
    height: 100%;
    border: 0px;
    background-color: var(--rojo);
    color: var(--blanco);
    font-family: var(--rajdhani);
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;
}

.boton-archivados:hover {
    background-color: #c75c4e;
}

.boton-abiertos {
    height: 100%;
    border: 0px;
    background-color: var(--azul);
    color: var(--blanco);
    font-family: var(--rajdhani);
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;
}

.boton-abiertos:hover {
    background-color: var(--azul-claro);
}


/* Loader skeleton */


/* Servicios Contratados */

.volver {
    background-color: var(--blanco);
    border: 1px solid var(--azul);
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
    font-family: var(--rajdhani);
    text-decoration: none;
    font-weight: 600;
    color: var(--azul);
}

.seccion-servicios {
    background-color: var(--blanco);
    margin-top: 45px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-height: 50vh;
}

.grid-servicios {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.intro-servicios {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-default,
.card-silver,
.card-gold,
.card-platinum,
.card-kit-digital {
    padding: 20px;
    border-radius: 5px;
}

.card-default {
    border: 2px solid var(--azul);
    background-color: var(--azul-claro);
}

.card-default h3 {
    color: var(--blanco);
    font-size: 22px;
}

.card-kit-digital {
    background-color: #e17055;
    color: var(--blanco);
    border: 2px solid #bc4e32;
}

.card-kit-digital h3 {
    color: var(--blanco);
}

.card-silver {
    border: 2px solid #C0C0C0;
    background-color: #F8F8F8;
}

.card-gold {
    border: 2px solid gold;
    background-color: rgb(252, 226, 80);
}

.card-platinum {
    border: 2px solid #2980b9;
    background-color: #4c9fd7;
}

.card-silver h3,
.card-gold h3 {
    color: var(--negro);
    font-size: 22px;
}

.card-platinum h3 {
    color: var(--blanco);
    font-size: 22px;
}


/* Sin tickets encontrados */

.no-tickets {
    text-align: center;
    padding: 20px;
    color: #999999;
    font-size: 16px;
    width: 100%;
}

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.pagination button {
    background-color: #eee;
    border: 0px;
    border-radius: 5px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--azul);
    font-family: var(--rajdhani);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.filtro-tickets {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
}

.filtro-tickets input {
    padding: 10px;
    padding-left: 18px;
    padding-right: 18px;
}

.filtro-tickets select {
    padding: 10px;
    padding-left: 18px;
    padding-right: 18px;
    color: var(--gris);
    cursor: pointer;
}


/* Nuevo Ticket */

.formulario-ticket {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    border-radius: 5px;
    background-color: var(--blanco);
}

.bloque-ticket {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    gap: 35px;
}

.bloque-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bloque-form p {
    font-size: 14px;
    position: relative;
    top: -5px;
}

.bloque-form p>a {
    color: var(--azul);
}

.desc-dpto-desarrollo {
    color: var(--blanco);
    font-size: 14px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--azul-claro);
    border-radius: 5px;
    font-weight: 600;
}

.desc-dpto-diseno {
    color: var(--blanco);
    font-size: 14px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #e67e22;
    border-radius: 5px;
    font-weight: 600;
}

.desc-dpto-marketing {
    color: var(--blanco);
    font-size: 14px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #8e44ad;
    border-radius: 5px;
    font-weight: 600;
}

.desc-dpto-redes_sociales {
    color: var(--blanco);
    font-size: 14px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #1abc9c;
    border-radius: 5px;
    font-weight: 600;
}

.opcional-msg {
    color: var(--gris);
}

.enviar-ticket {
    background-color: var(--azul);
    border: 0px;
    padding: 10px;
    cursor: pointer;
    color: var(--blanco);
    font-weight: bold;
    font-family: var(--rajdhani);
    width: fit-content;
    border-radius: 5px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 10px;
    transition: all 0.3s;
}

.enviar-ticket:hover {
    background-color: var(--azul-claro);
}

.intro-ticket {
    background-color: var(--blanco);
    padding: 30px;
    border-radius: 5px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

.intro-ticket h2 {
    font-size: 25px;
}

.intro-ticket p:nth-child(2) {
    margin-top: 15px;
}

.intro-ticket p {
    margin-top: 5px;
    font-size: 16px;
}

.datos-preticket {
    border-radius: 5px;
    margin-top: 40px;
    padding: 30px;
    background-color: var(--blanco);
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

.datos-preticket>p:first-child {
    color: var(--azul);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    gap: 10px;
    margin-bottom: 5px;
    font-weight: 699;
    font-family: var(--rajdhani);
}

.datos-preticket>p:first-child a {
    color: var(--negro-claro);
    font-weight: 600;
    font-size: 12px;
    background-color: var(--gris);
    color: var(--blanco);
    text-decoration: none;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    font-family: var(--rajdhani) !important;
    position: relative;
    top: -1px;
    user-select: none;
}

.datos-preticket p {
    font-size: 17px;
}

.parrafo-preticket {
    font-size: 16px !important;
}

.contacto-info-bloque {
    margin-top: 20px;
}

.contacto-info {
    font-size: 15px !important;
    margin-top: 5px;
}

.contacto-info span {
    color: var(--gris);
    font-weight: 700;
}

.labelFile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    border: 2px dashed #ccc;
    align-items: center;
    text-align: center;
    padding: 5px;
    color: #404040;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
}

.labelFile p {
    font-size: 14px;
    color: var(--gris);
    font-weight: 600;
}

#file {
    position: absolute;
    top: 0;
    left: 0;
    width: 96%;
    height: 90%;
    opacity: 0;
    cursor: pointer;
}

.files-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px;
}

.file {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 5px;
    position: relative;
}

.file img {
    width: 20px;
}

.file p {
    font-size: 14px;
}

.remove-file {
    background-color: var(--rojo);
    color: var(--blanco);
    border: 0px;
    width: 20px;
    height: 20px;
    font-family: var(--rajdhani);
    border-radius: 100px;
    padding-bottom: 2px;
    font-weight: 600;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
}


/* Empieza ahora */

.verificar-card-registro {
    position: relative;
    top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    background-color: var(--blanco);
    border-radius: 10px;
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    width: fit-content;
    margin: auto;
}

.verificar-card-registro img {
    width: 50px;
}


/* EmpiezaAhora.css */

.progress-bar-container {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 50px;
    margin-bottom: 20px;
}

.progress-bar {
    height: 7px;
    background-color: var(--azul);
    border-radius: 50px;
    transition: width 0.5s ease-in-out;
}

.step-group {
    margin-bottom: 20px;
}

.step-group span {
    color: var(--gris);
    font-weight: 500;
    font-size: 14px;
}

.grid-step-buttons {
    display: flex;
    justify-content: space-between;
}

.error-message {
    color: red;
    font-weight: bold;
}

.grid-registrarse {
    background-color: var(--blanco);
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    border-radius: 10px;
    width: 50%;
    margin: auto;
}

.mensaje-registro {
    padding-bottom: 20px;
}

.mensaje-registro h1 {
    font-size: 25px;
}

.grid-step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 5px;
}

.grid-step h2 {
    font-size: 22px;
}

.grid-step p {
    font-size: 16px;
}

.step-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.step-group label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.grid-step-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.grid-step button {
    background-color: var(--azul);
    color: var(--blanco);
    border: 0px;
    border-radius: 5px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.opcional {
    color: var(--azul);
}


/* Permiso Denegado */

.ticket-denegado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.acceso-denegado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--blanco);
    padding: 30px;
    border-radius: 5px;
    gap: 30px;
    position: relative;
    top: -50px;
}

.acceso-denegado img {
    width: 24px;
}


/* Verificar */

.verificando {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.verificar-card {
    position: relative;
    top: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    background-color: var(--blanco);
    border-radius: 10px;
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.verificar-card img {
    width: 50px;
}

.terminar-verificacion {
    border: 0px;
    background-color: var(--azul);
    color: var(--blanco);
    font-weight: 600;
    font-size: 15px;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 600;
    font-family: var(--rajdhani);
}


/* Ticket Creado */

.ticket-creado img {
    width: 120px;
}

.ticket-creado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bloque-ticket-creado {
    position: relative;
    top: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.bloque-ticket-creado a {
    color: var(--azul-claro);
}

.recordatorio {
    font-size: 13px;
}

.identificador {
    color: var(--blanco);
    background-color: var(--azul-claro);
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 5px;
    font-weight: 600;
}


/* Ticket */

.cabecera-ticket {
    background-color: var(--blanco);
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cabecera-ticket-uno {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.cabecera-ticket-uno img {
    width: 40px;
}

.cabecera-ticket-dos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.estado-del-ticket {
    background-color: var(--celeste-oscuro);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.ticket-respondido {
    background-color: var(--verde) !important;
}

.estado-del-ticket p {
    color: var(--blanco);
    font-size: 14px;
}

.tecnico-asignado img {
    width: 16px;
}

.tecnico-asignado p {
    font-size: 14px;
}

.tecnico-asignado {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.cabecera-ticket h1 {
    font-size: 23px;
    line-height: 25px;
    color: var(--negro);
}

.grid-ticket {
    display: grid;
    grid-template-columns: 0.35fr 1fr;
    margin-top: 20px;
    gap: 20px;
}

.datos-ticket {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: fit-content;
}

.datos-principales {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    gap: 20px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    background-color: var(--blanco);
    padding: 20px;
}

.bloque-datos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.bloque-datos p:last-child {
    color: var(--negro);
    font-weight: 600;
    text-align: right;
    margin-left: auto;
}

.bloque-datos p {
    font-size: 16px;
}

.color-dato p:last-child {
    color: var(--blanco);
}

.datos-archivos {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    gap: 20px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    background-color: var(--blanco);
    padding: 20px;
}

.datos-archivos p:first-child {
    color: var(--negro);
}

.archivo-ticket {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.archivo-ticket {
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
}

.descargar {
    background-color: var(--gris-claro);
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    width: 15px !important;
    border-radius: 5px;
    transition: all 0.3s;
}

.archivo-ticket:hover .descargar {
    background-color: var(--azul);
}

.archivo-ticket>img:first-child {
    object-fit: cover;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    padding: 5px;
}

.archivo-ticket p {
    font-family: var(--rajdhani);
    color: var(--negro);
    text-decoration: none;
    font-size: 15px;
}

.grid-archivos {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.no-archivos {
    font-size: 15px;
}

.copiar-ticket {
    border: 0px !important;
    padding: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--rojo);
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}

.copiar-ticket img {
    width: 15px;
}

.copiar-ticket p {
    font-size: 14px;
    color: var(--blanco);
}

.chat-deshabilitado-admin {
    position: relative;
}

.mensaje-deshabilitado {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba( 255, 255, 255, 0.25);
    backdrop-filter: blur( 4px);
    -webkit-backdrop-filter: blur( 4px);
    border: 1px solid rgba( 255, 255, 255, 0.18);
    z-index: 99999 !important;
}

.chat-ticket {
    background-color: var(--blanco);
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.titulo-chat {
    font-size: 20px;
    color: var(--negro);
    font-family: var(--rajdhani);
}

.chat {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 500px;
    overflow-y: scroll;
    padding: 10px;
    position: relative;
}

.reopen-notice {
    background-color: #e74d3c5e;
    color: var(--rojo);
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.overlay-chat {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba( 255, 255, 255, 0.25);
    backdrop-filter: blur( 4px);
    -webkit-backdrop-filter: blur( 4px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlay-chat p:first-child {
    color: var(--azul);
    font-weight: 600;
    text-align: center;
    font-size: 16px;
}

.overlay-chat p:nth-child(2) {
    font-size: 15px;
}

.overlay-chat a {
    font-family: var(--rajdhani);
    background-color: #ededed;
    color: var(--gris);
    text-decoration: unset;
    font-weight: 600;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 15px;
    border-radius: 10px;
    margin-top: 15px;
}

.quitar-scroll {
    overflow: hidden !important;
}

.mensaje-chat {
    background-color: var(--blanco);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 5px;
}

.mensaje-chat img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 500px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.mensaje-nombre {
    color: var(--azul);
    font-weight: 600;
}

.mensaje-usuario {
    font-size: 14px;
}

.mensaje-texto {
    width: 100%;
}

.mensaje-fecha {
    font-size: 14px;
    margin-top: 10px;
}

.contenido-mensaje {
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-wrap: break-word;
    word-break: break-word;
}

.mensaje-archivos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.archivo-adjunto-chat {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    text-decoration: none;
    width: fit-content;
    background-color: #fff;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}

.archivo-adjunto-chat img {
    width: 20px;
    height: 20px;
    padding: 5px;
}

.archivo-adjunto-chat p {
    font-size: 14px;
}

.layer-black {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-archivos {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 1000px;
    width: 100%;
    max-height: 450px;
    height: 100%;
    z-index: 999;
    background-color: var(--blanco);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
}

.grid-archivos-pop-up {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    max-height: 300px;
    height: 100%;
    overflow: auto;
}

.popup-contenido {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
}

.mensaje-descripcion a {
    color: #268dc2;
}

.cerrar-popup {
    background-color: var(--rojo);
    color: var(--blanco);
    border: 0px;
    cursor: pointer;
    font-family: var(--rajdhani);
    font-weight: 900;
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.ver-todos {
    background-color: #454952;
    cursor: pointer;
    border: 0px;
    font-family: var(--rajdhani);
    color: var(--blanco);
    font-weight: 600;
    border-radius: 5px;
    padding: 10px;
    transition: all 0.3s;
}

.ver-todos:hover {
    background-color: var(--gris);
}

.ql-clean {
    display: none !important;
}

.mensaje-descripcion ol,
.mensaje-descripcion ul {
    font-size: 14px;
    padding-left: 12px;
}

.chat-ticket .ql-toolbar.ql-snow {
    border: 0px;
}

.chat-ticket .ql-container.ql-snow {
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.chat-ticket .ql-tooltip {
    left: 0px !important;
}

.ql-snow .ql-editor h1,
.mensaje-descripcion h1 {
    font-size: 20px !important;
}

.ql-snow .ql-editor h2,
.mensaje-descripcion h2 {
    font-size: 19px !important;
}

.ql-snow .ql-editor h3,
.mensaje-descripcion h3 {
    font-size: 18px !important;
}

.mensaje-descripcion p {
    font-size: 15px;
}

.enviar-mensaje {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.escribiendo {
    position: absolute;
    top: 5px;
    left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.escribiendo p {
    font-family: var(--rajdhani);
    font-size: 15px;
}


/* Subida de archivos en ticket */

.gestor-archivo-chat {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.error-chat {
    margin-bottom: 20px;
    text-align: center;
}

.custum-file-upload {
    margin-bottom: 10px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--gris-claro);
    background-color: rgba(255, 255, 255, 1);
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
}

.custum-file-upload .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custum-file-upload .icon img {
    height: 20px;
    opacity: 0.7;
}

.custum-file-upload .text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custum-file-upload .text span {
    font-weight: 500;
    color: var(--gris);
    font-size: 14px;
}

.custum-file-upload input {
    display: none;
}

.file-list-ticket {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}


/* Loader */

.loader-grid {
    min-height: 90vh;
    padding-top: 0;
    padding-bottom: 80px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* From Uiverse.io by Siyu1017 */

.windows-loading-spinner {
    box-sizing: border-box;
    width: 3rem;
    height: 3rem;
    padding: 2px;
    overflow: visible;
    position: relative;
    top: -50px;
}

.windows-loading-spinner>circle {
    stroke: var(--azul);
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transition: all 0.2s ease-in-out 0s;
    animation: 2s linear 0s infinite normal none running loading-spinner;
}

@keyframes loading-spinner {
    0% {
        stroke-dasharray: 0.01px, 43.97px;
        transform: rotate(0);
    }
    50% {
        stroke-dasharray: 21.99px, 21.99px;
        transform: rotate(450deg);
    }
    100% {
        stroke-dasharray: 0.01px, 43.97px;
        transform: rotate(1080deg);
    }
}


/* Admin */

.filtro-tickets-admin {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.filtro-tickets-opciones {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.admin-prepanel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 20px;
}

.prepanel-card {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 10px;
    text-decoration: none;
    transition: all 0.3s;
}

.prepanel-card:hover {
    background-color: #f7f7f7;
}

.coming-soon-card {
    background-color: #e74c3c;
}

.prepanel-card h3 {
    color: var(--negro);
    opacity: 0.7;
    font-size: 22px;
}

.coming-soon-card h3 {
    color: var(--blanco);
    opacity: 1;
}

.coming-soon-card p {
    color: var(--blanco);
    font-size: 15px;
    margin-top: 10px;
}

.prepanel-card button {
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--blanco);
    background-color: var(--azul);
    border-radius: 5px;
    border: 0px;
    margin-top: 30px;
    cursor: pointer;
}

.coming-soon-card button {
    background-color: var(--blanco);
    color: #e74c3c;
}

.prepanel-number {
    font-size: 15px;
    color: var(--negro);
    margin-top: 10px;
}

.prepanel-warn-ticket {
    color: var(--verde) !important;
    background-color: #e0ffed;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 10px;
}

.prepanel-warn-usuarios {
    color: var(--azul) !important;
    background-color: #b3e6ff;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 10px;
}

.sin-responder {
    font-size: 13px;
    font-weight: 600;
    color: var(--rojo);
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    text-align: center;
    background-color: #ffcac4;
}

.respondido {
    font-size: 13px;
    color: var(--blanco);
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px;
    text-align: center;
    padding-left: 10px;
    font-weight: 600;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #2ecc71;
}

.user-manager {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 5px;
    background-color: var(--blanco);
}

.grid-usuarios {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px;
}

.user-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 20px;
    gap: 5px;
    border-radius: 5px;
}

.user-img {
    object-fit: cover;
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.user-image-grid {
    position: relative;
}

.user-usuario {
    font-size: 15px;
}

.user-rol {
    font-size: 14px;
    padding: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    margin-top: 10px !important;
}

.user-rol-user {
    background-color: var(--azul);
    width: fit-content;
    color: var(--blanco);
    text-align: center;
    margin: auto;
}

.user-rol-admin {
    background-color: var(--verde);
    width: fit-content;
    color: var(--blanco);
    text-align: center;
    margin: auto;
}

.user-fecha {
    color: var(--negro);
    margin-top: 10px;
    font-size: 15px;
}

.user-botones {
    margin-top: 20px;
}

.user-botones button {
    background-color: var(--azul);
    color: var(--blanco);
    border: 0px;
    padding: 5px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-weight: 500;
    font-family: var(--rajdhani);
}

.verificacion {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: contain;
    width: 20px;
    hyphenate-limit-chars: 20px;
}

.user-data h3 {
    font-size: 20px;
}

.users-pagination {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
}

.filtrado-users {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}


/* Modal */

.react-aria-RangeCalendar {
    width: 100%;
    max-width: 100%;
    color: var(--text-color);
    & header {
        display: flex;
        align-items: center;
        margin: 0 4px .5rem 4px;
        .react-aria-Heading {
            flex: 1;
            margin: 0;
            text-align: center;
            font-size: 1.375rem;
        }
    }
    .react-aria-Button {
        width: 2rem;
        height: 2rem;
        padding: 0;
        border: 0px;
        background-color: var(--azul);
        color: var(--blanco);
        border-radius: 5px;
        cursor: pointer;
    }
    & table {
        border-collapse: collapse;
        width: 100%;
        font-family: var(--rajdhani);
        & td {
            padding: 2px 0;
            cursor: pointer;
        }
    }
    .react-aria-CalendarCell {
        width: 100%;
        line-height: 2.286rem;
        text-align: center;
        border-radius: 6px;
        cursor: pointer;
        outline: none;
        forced-color-adjust: none;
        &[data-outside-month] {
            display: none;
        }
        &[data-pressed] {
            background: var(--azul);
        }
        &[data-focus-visible] {
            outline: 2px solid var(--azul);
            outline-offset: -2px;
        }
        &[data-selected] {
            background: var(--azul);
            color: var(--blanco);
            border-radius: 0;
            &[data-focus-visible] {
                outline-color: var(--highlight-foreground);
                outline-offset: -3px;
            }
        }
        &[data-selection-start] {
            border-start-start-radius: 6px;
            border-end-start-radius: 6px;
        }
        &[data-selection-end] {
            border-start-end-radius: 6px;
            border-end-end-radius: 6px;
        }
    }
}


/* Estilos para la animación de fade-in */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    animation: fadeIn 0.5s ease forwards;
    /* Aplica la animación fadeIn */
    transform: scale(0.9);
    transition: transform 0.5s ease;
}


/* Cuando el modal esté visible */

.modal-overlay.show {
    opacity: 1;
    pointer-events: auto;
}

.modal-content.show {
    transform: scale(1);
    /* Escala de vuelta al tamaño normal */
}

@media only screen and (max-height:700px) {
    .modal-content {
        max-height: 400px;
        overflow: auto;
    }
    .user-details {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .modal-content {
        padding-bottom: 60px;
    }
}

.modal-close {
    background-color: var(--rojo);
    color: var(--blanco);
    font-weight: 600;
    width: 25px;
    height: 25px;
    font-family: var(--rajdhani);
    border: 0px !important;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    float: right;
}

.modal-close:hover {
    background-color: #922013;
}

.user-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
}

.selected-user-img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

.user-details-data {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 40px;
}

.user-details-field p:first-child {
    color: var(--negro);
    font-weight: 600;
    text-align: left;
}

.user-details-field p:last-child {
    text-align: right;
}

.user-details-field p {
    font-size: 16px;
}

.user-details-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.texto-verificado {
    color: var(--verde);
}

.texto-sin-verificar {
    color: var(--rojo);
}

.modal-content button:last-child {
    background-color: var(--azul);
    color: var(--blanco);
    font-weight: 600;
    font-family: var(--rajdhani);
    border: 0px !important;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    float: right;
}

.user-details input,
.user-details select {
    width: 50%;
    text-align: right;
}

.user-details h3 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-details h3 input {
    width: fit-content !important;
    text-align: center;
    font-size: 20px;
}


/* Footer */

footer {
    background-color: var(--negro-claro);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

footer a {
    color: var(--blanco);
    font-family: var(--rajdhani);
    text-decoration: none;
}


/* Control de Versiones */

.control-versiones {
    background-color: var(--blanco);
    padding: 30px;
    border-radius: 5px;
}

.control-versiones h1 {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
}

.control-versiones h1 span {
    color: var(--azul);
    border-radius: 5px;
}

.bloque-versiones {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

.bloque-version {
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 30px;
    border-radius: 5px;
}

.bloque-version p {
    font-size: 15px;
    font-weight: 600;
}

.bloque-version ul {
    padding-left: 20px;
}

.bloque-version ul>li {
    color: var(--gris);
    font-weight: 500;
    opacity: 0.7;
    font-size: 15px;
    font-family: var(--rajdhani);
}


/* Tracking proyectos */

.grid-tracking-proyectos {
    padding: 20px;
    background-color: var(--blanco);
    border-radius: 8px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


/* Estilos para el formulario en una sola fila */

.grid-crear-proyecto {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.row-crear-proyecto-uno {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-top: 30px;
}

.row-crear-proyecto-uno .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


/* Cada grupo de formulario ocupará un espacio equitativo */

.grid-crear-proyecto .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.user-select-container {
    width: 100%;
    display: flex;
    position: relative;
}

.user-select-container input {
    width: 100%;
}

.dropdown-usuarios {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    border-radius: 5px;
    font-family: var(--rajdhani);
    font-weight: 500;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999999999;
    background-color: var(--blanco);
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.dropdown-usuarios>div {
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.dropdown-usuarios>div:hover {
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #f7f7f7;
}


/* Estilos para los botones de acción en el modal */

.modal-actions {
    display: flex;
    gap: 10px;
}


/* Estilos para la superposición del modal */

.fg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}


/* Contenedor del modal */

.modal-container {
    position: relative;
    top: 30px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 95%;
    width: 90%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    max-width: 900px;
}

.tabla-proyectos {
    margin-top: 20px;
    border-radius: 5px;
}

.filtro-proyectos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.nombre-proyecto {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.nombre-proyecto a {
    color: var(--azul);
}

.nombre-proyecto button {
    display: flex;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
}

.nombre-proyecto button img {
    width: 16px;
}

.anadir-proyectos {
    background-color: var(--azul);
    border: 0px;
    cursor: pointer;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    font-family: var(--rajdhani);
    color: var(--blanco);
    font-weight: 600;
    border-radius: 5px;
    width: fit-content;
    font-size: 15px;
}

.cancelar-proyecto {
    background-color: #ffc7c0;
    border: 0px;
    cursor: pointer;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    font-family: var(--rajdhani);
    color: var(--rojo);
    font-weight: 600;
    border-radius: 5px;
    width: fit-content;
    font-size: 15px;
}

.tabla-proyectos .tabla-header {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
}

.tabla-proyectos .tabla-header>div {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: var(--rajdhani);
    font-weight: 600;
    background-color: #f7f7f7;
}

.tabla-proyectos .tabla-header>div,
.tabla-proyectos .tabla-row>div {
    min-width: 100px;
}

.tabla-proyectos .tabla-row {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
}

.tabla-proyectos .tabla-row {
    transition: all 0.3s;
}

.tabla-proyectos .tabla-row:hover {
    background-color: #fafafa;
}

.tabla-proyectos .tabla-row>div {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: var(--rajdhani);
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.022) 0px 0px 0px 1px;
}

.tabla-proyectos .custom-select-container {
    position: relative;
}

.tabla-proyectos .custom-select-dropdown {
    position: absolute;
    top: 130%;
    left: 0;
    background-color: var(--blanco);
    z-index: 999;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    width: 200px;
    border-radius: 5px;
}

.tabla-proyectos .custom-select-option,
.tabla-proyectos .custom-select-trigger {
    cursor: pointer;
    border-radius: 5px;
    padding: 3px;
    padding-left: 20px;
    user-select: none;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 600;
}

.tabla-proyectos .sin_ejecutar .custom-select-trigger {
    background-color: #ffbe76;
    color: var(--negro);
    text-align: center;
}

.tabla-proyectos .custom-select-option.sin_ejecutar {
    background-color: #ffbe76;
    color: var(--negro);
    text-align: center;
}

.tabla-proyectos .en_proceso .custom-select-trigger {
    background-color: var(--azul);
    color: var(--blanco);
    text-align: center;
}

.tabla-proyectos .custom-select-option.en_proceso {
    background-color: var(--azul);
    color: var(--blanco);
    text-align: center;
}

.tabla-proyectos .aceptado .custom-select-trigger {
    background-color: var(--verde);
    color: var(--blanco);
    text-align: center;
}

.tabla-proyectos .custom-select-option.aceptado {
    background-color: var(--verde);
    color: var(--blanco);
    text-align: center;
}


/* Single Proyecto */

.single-proyecto {
    padding: 20px;
    background-color: var(--blanco);
    border-radius: 8px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tipo-proyecto {
    background-color: var(--celeste-oscuro);
    width: fit-content;
    color: var(--blanco);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    font-size: 15px;
}

.grid-timeline {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 10px;
    margin-top: 45px;
    margin-bottom: 45px;
    padding-left: 30px;
    padding-right: 30px;
}

.texto-pre-timeline {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
    text-align: center;
}

.timeline-proyecto {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: flex-start;
    margin-top: 45px;
}

.item-timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: relative;
    padding-right: 20px;
}

.item-timeline::after {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background-color: var(--azul);
}

.timeline-proyecto .item-timeline:last-child {
    padding-right: 0px;
}

.proyecto-sin_ejecutar .icono-timeline {
    border: 2px solid #268ec234;
}

.proyecto-sin_ejecutar .icono-timeline img {
    opacity: 0.3;
}

.proyecto-sin_ejecutar::after {
    background-color: #268ec234;
}

.proyecto-sin_ejecutar .texto-timeline {
    opacity: 0.5;
}

.icono-timeline {
    border: 2px solid var(--azul);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    z-index: 99;
    background-color: var(--blanco);
    position: relative;
}

.check-aceptado {
    position: absolute;
    bottom: -10px;
    right: -10px;
}

.texto-timeline-aceptado {
    background-color: #2ecc7039;
    color: #2ecc71;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
}

.texto-timeline-en_proceso {
    background-color: #268ec22f;
    color: #268dc2;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
}

.icono-timeline img {
    width: 25px;
    height: 25px;
}

.texto-timeline {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 150px;
}

.texto-timeline h3 {
    font-size: 20px;
}

.texto-timeline p {
    font-size: 15px;
}


/* User Proyectos */

.user-proyectos {
    background-color: var(--blanco);
    border-radius: 5px;
    padding: 30px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 70vh;
}

.grid-user-proyectos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 25px;
    gap: 15px;
}

.item-user-proyectos {
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    text-decoration: none;
    padding: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.item-user-proyectos:hover {
    background-color: #f7f7f7;
}

.item-user-proyectos h3 {
    font-size: 20px;
}

.item-user-proyectos p {
    font-size: 15px;
}

.proyecto-terminado {
    background-color: #2ecc7039;
    color: #2ecc71;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    width: fit-content;
    margin-top: 10px;
}

.proyecto-en-progreso {
    background-color: #268ec22f;
    color: #268dc2;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    width: fit-content;
    margin-top: 10px;
}


/* Toastify */

.Toastify {
    position: relative;
    z-index: 22222222222 !important;
}


/* Responsive */

.table-overflow {
    max-width: 100%;
    overflow-x: auto;
}

.tickets-table {
    width: 100%;
    border-collapse: collapse;
}

input[type="checkbox"] {
    display: none;
}

.menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    justify-content: space-between;
}

.menu-icon .line {
    width: 30px;
    height: 2px;
    background-color: var(--negro);
    transition: all 0.1s;
}

.menu-icon .top {
    position: relative;
    top: 8px;
}

.menu-icon .bottom {
    position: relative;
    bottom: 8px;
}

#menu-toggle:checked+.menu-icon .top {
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 0;
    background-color: var(--azul);
}

#menu-toggle:checked+.menu-icon .bottom {
    transform: rotate(-45deg);
    position: absolute;
    top: 15px;
    left: 0;
    background-color: var(--azul);
}

.adhoc-burger {
    display: none;
}

.menu-responsive {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--blanco);
    width: 68%;
    left: -100%;
    height: 100%;
    z-index: 9999999999;
    transition: all 0.3s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 30px;
}

#menu-toggle:checked+label+.menu-responsive {
    left: 0%;
}

.menu-responsive .user-actions {
    display: grid;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.menu-responsive .user-actions a {
    text-align: center;
}

.lateral-logo {
    width: 170px;
    margin-bottom: 20px;
}

.menu-responsive .logout {
    padding: 5px !important;
    max-height: 30px;
    border-radius: 10px !important;
}

@media only screen and (max-width: 1400px) {
    .site-width {
        padding-left: 30px;
        padding-right: 30px;
    }
    .departments {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 35px;
        margin-top: 65px;
    }
    .profile-actions {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (max-width: 1100px) {
    .tabla-proyectos {
        overflow-x: scroll;
    }
    .popup-archivos {
        width: 90%;
    }
    .grid-archivos-pop-up {
        grid-template-columns: repeat(3, 1fr);
        max-width: unset;
    }
    .dashboard-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 35px;
    }
    .profile-and-menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
    .bloque-ticket {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .datos-preticket {
        border-radius: 5px;
        margin-top: 10px;
        padding: 30px;
        background-color: var(--blanco);
        box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    }
    .intro-ticket,
    .datos-preticket,
    .formulario-ticket {
        padding: 20px;
    }
    .filtro-tickets-admin,
    .filtro-tickets {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .grid-ticket {
        display: flex;
        flex-direction: column;
    }
    .datos-principales {
        max-height: 100px;
        overflow-y: scroll;
    }
    .filtro-tickets-opciones {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .filtrado-users {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
    .bloque-versiones {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 35px;
    }
    .timeline-proyecto {
        display: flex;
        flex-direction: column;
    }
    .item-timeline {
        display: grid;
        grid-template-columns: 0.05fr 0.95fr;
        padding-right: 0px;
        padding-bottom: 20px;
        width: 100%;
        gap: 15px;
    }
    .item-timeline::after {
        position: absolute;
        top: 0px;
        left: 25px;
        width: 2px;
        height: 100%;
        content: "";
    }
    .timeline-proyecto .item-timeline:last-child {
        padding-bottom: 0px;
    }
    .grid-timeline {
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.grid-burger {
    display: none;
}

@media only screen and (max-width: 767px) {
    .grid-user-proyectos {
        grid-template-columns: repeat(1, 1fr);
    }
    .cabecera-ticket {
        background-color: var(--blanco);
        box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        position: relative;
    }
    .cabecera-ticket-uno img {
        width: 25px;
    }
    .cabecera-ticket-uno {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    .estado-del-ticket {
        position: absolute;
        top: 0;
        right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 0;
    }
    .cabecera-ticket-dos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        margin-top: 10px;
    }
    .chat-ticket {
        padding: 0;
        padding-bottom: 20px;
    }
    .titulo-chat {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .tecnico-asignado select {
        width: 100%;
    }
    .cabecera-ticket-texto {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .cabecera-ticket-texto h1 {
        font-size: 20px;
        line-height: 20px;
    }
    .mensaje-chat {
        background-color: var(--blanco);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 15px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        border-radius: 5px;
    }
    .profile {
        display: grid;
        grid-template-columns: 1fr;
        padding: 20px;
        gap: 10px;
    }
    .profile-pic {
        width: 60px;
        height: 60px;
    }
    .profile-name {
        color: var(--negro);
        font-weight: 800;
        font-size: 16px;
        text-align: center;
    }
    .error-404 {
        width: 80%;
    }
    .edit-profile,
    .logout {
        font-size: 13px;
    }
    .profile-and-menu {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .bloque-secciones h3 {
        font-size: 18px;
    }
    .admin-prepanel {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        margin-top: 20px;
    }
    .dashboard-grid {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }
    .departments {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 35px;
        margin-top: 65px;
    }
    .intro-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: 20px;
        margin-top: 30px;
        width: 100%;
    }
    .grid-usuarios {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    .user-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        gap: 5px;
        border-radius: 5px;
    }
    .user-data h3 {
        font-size: 16px;
        line-height: 16px;
    }
    .grid-servicios {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .modal-content {
        width: 76%;
    }
    .user-details h3 input {
        width: -moz-fit-content !important;
        width: fit-content !important;
        text-align: center;
        font-size: 16px;
    }
    .user-details-field p {
        font-size: 14px;
    }
    .intro {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .comunidad {
        display: flex;
        flex-direction: column-reverse;
        gap: 45px;
        place-items: center;
    }
    .bg-comunidad {
        background-color: var(--blanco);
        margin-top: 50px;
        padding-top: 45px;
        padding-bottom: 45px;
    }
    h1 {
        font-size: 30px;
        line-height: 30px;
    }
    h2 {
        font-size: 25px;
    }
    h3 {
        font-size: 20px;
    }
    h1>br {
        display: none;
    }
    p {
        font-size: 16px;
    }
    .adhoc-burger {
        display: unset;
    }
    .logo-link+div {
        display: none;
    }
    .ticket-creado img {
        width: 80px;
    }
    .formulario-inicio {
        display: flex;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        position: relative;
        width: 80%;
        top: -30px;
        border-radius: 5px;
        padding: 30px;
        padding-bottom: 60px;
        text-align: left;
        background-color: var(--blanco);
    }
    .grid-burger {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
    .dropdown-notificaciones {
        transition: all 0.3s;
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 0px;
        left: 0;
        width: 70%;
        height: 100vh;
        background-color: var(--blanco);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 0px !important;
        border-radius: 5px;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #ccc transparent;
    }
    .grid-notificaciones {
        padding: 30px;
    }
    .comunidad img {
        width: 100%;
        height: auto !important;
    }
    .inicia-sesion {
        display: grid;
        width: unset;
    }
    .formulario-inicio h1 {
        font-size: 25px;
    }
    .grid-registrarse {
        width: 92%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .grid-step {
        padding-left: 15px;
        padding-right: 15px;
    }
    .verificar-card-registro {
        padding-left: 20px;
        padding-right: 20px;
    }
    .grid-archivos-pop-up {
        grid-template-columns: repeat(1, 1fr);
        max-width: unset;
    }
}

@media only screen and (max-width: 562px) {
    .intro-info {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
}

@media only screen and (max-width: 500px) {
    .header-logo,
    .lateral-logo {
        width: 140px;
    }
    .grid-notificaciones h3 {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}